import React, { useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker,Autocomplete ,} from '@react-google-maps/api';
import { Form } from 'react-bootstrap';
import { useRef } from 'react';
import useOnclickOutside from "react-cool-onclickoutside";
import usePlacesAutocomplete, {
 geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import PlacesAutocomplete from 'react-places-autocomplete';


const containerStyle =  {
  width: '80%',
  height: '500px'
};




function GoogleMapComponent({returnCordinates,schoolLat,schoolLng}) {
  const [center,setCenter] =useState( {
    lat: schoolLat==null?21.24525255178479:schoolLat,
    lng:schoolLng==null?81.6348634811212:schoolLng, 
  });
  
    const [map, setMap] = React.useState( /**  @type*/ null);
    
 /**  @type*/
   // const originRef=useRef();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyA3GkUvfEQeMptos3NG_yufZ7tvZCenvJk",
    libraries:['places']
  })
  const [zoom,setZoom]=useState(12);
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
   //map.fitBounds(bounds);
    //map.setZoom(zoom);
    setMap(map)

  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  
const [address, setAddress] = useState("");
const handleSelect =async value=>{

const result=await geocodeByAddress(value);
const latLng=await getLatLng(result[0]);
setAddress(value);
setCordinates(latLng);
returnCordinates(latLng);
map.panTo(latLng);
map.setCenter({
  lat: latLng.lat,
  lng: latLng.lng
});
setCenter({
  lat: latLng.lat,
  lng: latLng.lng
});
map.setZoom(12)


}
const [cordinates,setCordinates]=useState({lat:schoolLat,lng:schoolLng})

function onMarkerDrag(evt) {
  //console.log("ddddddddddddd");
  // console.log(evt.google.maps.Marker.getPosition().lat());
  console.log(evt.latLng.lat());
  returnCordinates({lat:evt.latLng.lat(),lng:evt.latLng.lng()});
  // setCordinates
};

  return isLoaded ? (
    <>
     <div className="google-map-picker-box">
            <div className='search-place-box'>

         <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect} 
         >
          {({getInputProps, suggestions, getSuggestionItemProps, loading})=><div>
            <input
         
            {...getInputProps({placeholder:"Type Address"})}/>
            <div>
              {loading?<div>....Loading</div>:null}
              {suggestions.map((suggestion)=>{
                const style={
                  background:suggestion.active?"#41b6e6":"#fff"
                }
                return <div {...getSuggestionItemProps(suggestion,{style})}>{suggestion.description}</div>

              })}
            </div>
            </div>}

         </PlacesAutocomplete>
                
            </div>
     
        <GoogleMap
    
          mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}

    
      >
     {cordinates && <Marker
     draggable={true}
     onDrag={onMarkerDrag}
     position={cordinates} />}
        { /* Child components, such as markers, info windows, etc. */ }
        <></>
      </GoogleMap>


            </div>
      
    </>
    
  ) : <></>
  
  
 
}


export default React.memo(GoogleMapComponent)