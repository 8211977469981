import React, { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker,Autocomplete, DirectionsRenderer,InfoWindow, OverlayView} from '@react-google-maps/api';


import { Button, Form } from 'react-bootstrap';
import { useRef } from 'react';
import useOnclickOutside from "react-cool-onclickoutside";
import usePlacesAutocomplete, {
 geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import PlacesAutocomplete from 'react-places-autocomplete';
import tripService from '../../services/tripService';
import FirebaseService from '../../services/firebase';



const containerStyle =  {
  width: '100%',
  height: '500px' 
};

// const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
//   return {
//       x: offsetWidth + labelAnchor.x,
//       y: offsetHeight + labelAnchor.y,
//   };
// };

function GoogleMapRouteLiveTrack({allLocation, schoolLat,schoolLng,schoolName,isPickUp,busId}) {
  const[isLoading,setIsLoading]=useState(false);
    const [map, setMap] = React.useState( /**  @type*/ null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const[ childWaypoints,setChildWayPoint]=useState([]);
    const [time, setTime] = useState(Date.now());
    const [zoom,setZoom]=useState(14);
    const mapRef = useRef(null);
    const [centerPosition, setCenterPosition] = useState({
      lat: schoolLat,
      lng: schoolLng
  });
      const [busLatLng,setBusLatLng] = useState();
      useEffect(() => {
        // const interval = setInterval(() => setTime(Date.now()), 1000);

        const interval = setInterval(() => {
          console.log('=============get bus lat lng every 3 sec');
          getBusLatLng();
        }, 3000);
     
        return () => clearInterval(interval);
      }, []);
      function getBusLatLng() {
  
      
        FirebaseService.getData("bus",busId.toString()).then(
          (data) => {

            if(data!=null){
          
              if(data.data.lat!=null&&data.data.lng!=null&&data.data.lat!=""&&data.data.lng!="")
            {   
              const lat=parseFloat(data.data.lat);
              const lng=parseFloat(data.data.lng);
              // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz",lat,lng);
              
            setBusLatLng({"lat": lat,"lng":lng,"title":"Bus A"})
            }
            }
          }
        );
        // tripService.getBusInfoByTHID(THID).then(
        //   (data) => {
        //     if(data!=null){
          
        //       if(data.lng!=null&&data.lat!=null)
        //     {   
        //     setBusLatLng({"lat":data.lat,"lng":data.lng,"title":data.title})
        //     }
        //     }
        //   }
       
        // );

      }
    useEffect(() => {
      getBusLatLng();
      const docData = []; 
        for (var i = 0; i < allLocation.length; i++) {
          var p = allLocation[i];
        
          var latLng = {
            location: { lat: isPickUp==1?p.pick_up_lat:p.drop_lat, lng:isPickUp==1? p.pick_up_lng:p.drop_lng },
            stopover: true,
         
          };
    
          docData.push(latLng);
     
        }
        setChildWayPoint(docData);
        makeDirection(docData)
    
      
    }, []);

    async function makeDirection(childWaypoints) {
        
      console.log("------- -----------------------");
      console.log(childWaypoints)
        const directionsService = new window.google.maps.DirectionsService()
      await directionsService.route(
            {
            origin:{lat:schoolLat, lng:schoolLng},
            waypoints:childWaypoints,
            
            destination:{lat:schoolLat, lng:schoolLng},
            travelMode:  window.google.maps.TravelMode.DRIVING,
            
          },
          
        (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                setDirectionsResponse(result)
        
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
          )
       
    }

  
  
    
 /**  @type*/
   // const originRef=useRef();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyA3GkUvfEQeMptos3NG_yufZ7tvZCenvJk",
    libraries:['places']
  })


  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(centerPosition);
   // map.fitBounds(bounds);
   mapRef.current = map;
    setMap(map)

  

  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  
  function handleCenter() {
    if (!mapRef.current) return;

    const newPos = mapRef.current.getCenter().toJSON();
    setCenterPosition(newPos);
  }

  return  isLoaded ? (
    <>
        {/* <br/>
    <Button onClick={makeDirection}>Check Route</Button>
    <br/> */}
    <br/>
        <GoogleMap
        mapContainerStyle={containerStyle}
        center={centerPosition}   
        zoom={zoom}
        onLoad={onLoad}
        onDragEnd={handleCenter}
        onUnmount={onUnmount}        
      >
  
{    directionsResponse?  
<DirectionsRenderer 
  directions={directionsResponse}
  options={{ suppressMarkers: true }}
  />:<></>}
  
     <Marker
    position={{ lat: schoolLat, lng: schoolLng }}
     icon={{url:'https://techashna.com/busapp/images/school_map.png',
      anchor: new window.google.maps.Point(17, 46),
      scaledSize: new window.google.maps.Size(37, 37),
      fillColor: '#EB00FF'
    }}
    
    //label="ashish"dddd

  
  />
  {  busLatLng==null?<></>:  <Marker
    position={{ lat: busLatLng.lat, lng: busLatLng.lng }}
     icon={{url:'https://techashna.com/busapp/images/bus_map.png',
      anchor: new window.google.maps.Point(17, 46),
      scaledSize: new window.google.maps.Size(37, 37),
      fillColor: '#EB00FF'
    }}

  />}
  <OverlayView
    key='mwl'
    position={{ lat: schoolLat, lng: schoolLng }}
    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    //  getPixelPositionOffset={(x, y) => getPixelPositionOffset(x, y, {     x: -130, y: -100 })}
    >
    <div
        style={{
            background: `#203254`,
            padding: `7px 12px`,
            fontSize: '11px',
            color: `white`,
            borderRadius: '4px',
        }}
    >
        {schoolName}
    </div>
</OverlayView>
    {childWaypoints.map((waypt, index)=> (
      <>
  <Marker
    position={{ lat: waypt.location.lat, lng: waypt.location.lng }}
     icon={{url:'https://techashna.com/busapp/images/student_map.png',
      anchor: new window.google.maps.Point(17, 46),
      scaledSize: new window.google.maps.Size(37, 37),
      fillColor: '#EB00FF'
    }}
    // label={waypt.student_name}
  />
  <OverlayView
  key={waypt.location.lat}
  position={{ lat: waypt.location.lat, lng: waypt.location.lng }}
  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //  getPixelPositionOffset={(x, y) => getPixelPositionOffset(x, y, {     x: -130, y: -100 })}
  >
  <div
      style={{
          background: `#203254`,
          padding: `7px 12px`,
          fontSize: '11px',
          color: `white`,
          borderRadius: '4px',
      }}
  >   
      {allLocation[index].first_name +" "+ allLocation[index].last_name+" "+allLocation[index].pick_up_order}
  </div>
</OverlayView>
</>
))}
      </GoogleMap>
      
    </>
    
  ) : <></>
  
  
 
}


export default React.memo(GoogleMapRouteLiveTrack)