import api_url from "../my_config"; 
class UserServices{
    getUrl=api_url+'/api/get_user';
    getUrlAllUserByRoleId=api_url+'/api/get_user/role';
    getUrlAllUserBySchoolId=api_url+'/api/get_user/school';
    addUrl=api_url+'/api/add_user';
    updateUrl=api_url+'/api/update_user';
    syncUrl=api_url+'/api/sync_user';
    unSyncUrl=api_url+'/api/sync_user/delete';
    getSyncUrl=api_url+'/api/sync_user/user_id';
    getSyncUrlBySChoolAndUser=api_url+'/api/sync_user/school/user';
    getSyncEmpBySChool=api_url+'/api/sync_user/school/emp';
    getSyncBusEmpBySChool=api_url+'/api/sync_user/school/busemp';
    updateCreditUrl=api_url+'/api/update_user_creditional';
    updateWebFcmUrl=api_url+'/api/update_user/web_fcm';
    async getSyncBusEmpSChoolId(school_id) {
      // console.log(this.getSyncUrl+'/'+user_id);
  
      try{
      // Default options are marked with *
      const response = await fetch(this.getSyncBusEmpBySChool+'/'+school_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getSyncEmpSChoolId(school_id) {
      // console.log(this.getSyncUrl+'/'+user_id);
    
      try{
      // Default options are marked with *
      const response = await fetch(this.getSyncEmpBySChool+'/'+school_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getSyncUsersSChoolUserRoleId(user_id,school_id,role_id) {
      // console.log(this.getSyncUrl+'/'+user_id);
    
      try{
      // Default options are marked with *
      const response = await fetch(this.getSyncUrlBySChoolAndUser+'/'+school_id+'/'+user_id+'/'+role_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getSyncUsersSChoolUserId(user_id,school_id) {
      // console.log(this.getSyncUrl+'/'+user_id);
    
      try{
      // Default options are marked with *
      const response = await fetch(this.getSyncUrlBySChoolAndUser+'/'+school_id+'/'+user_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getSyncUsers(user_id) {
      console.log(this.getSyncUrl+'/'+user_id);
     
      try{
      // Default options are marked with *
      const response = await fetch(this.getSyncUrl+'/'+user_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    
     // parses JSON response into native JavaScript objects
    }
 


     async getDataById(id) {
       try{
       // Default options are marked with *
       console.log("-------------------------");
       console.log(this.getUrl+'/'+id);
       const response = await fetch(this.getUrl+'/'+id, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
           'Content-Type': 'application/json',
           'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
           // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         // body: JSON.stringify(data) // body data type must match "Content-Type" header
       });
           if(response.status==200){
             const data = await response.json();
             if(data['response']==200){
             if(data['data']==null)
             return null;
            else return data['data']; 
             }
             else return null;
           }else return null ;
         }catch(e){
           return null;
         }
     
      // parses JSON response into native JavaScript objects
     }
        async getData(){
          try{
            // Default options are marked with *
            const response = await fetch(this.getUrl, {
              method: 'GET', // *GET, POST, PUT, DELETE, etc.
              mode: 'cors', // no-cors, *cors, same-origin
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'same-origin', // include, *same-origin, omit
              headers: {
                'Content-Type': 'application/json',
                'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              redirect: 'follow', // manual, *follow, error
              referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
              // body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
                if(response.status==200){
                  const data = await response.json();
                  if(data['response']==200){
                  if(data['data']==null)
                  return null;
                 else return data['data']; 
                  }
                  else return null;
                }else return null ;
              }catch(e){
                return null;
              }
        }
        async getAllDataBySchoolIdSearch(id,search) {
          try{
          // Default options are marked with *get_user/search/{id}/{q}
          const response = await fetch(this.getUrlAllUserBySchoolId+'/search/'+id+"/"+search, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
              'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            // body: JSON.stringify(data) // body data type must match "Content-Type" header
          });
              if(response.status==200){
                const data = await response.json();
                if(data['response']==200){
                if(data['data']==null)
                return null;
               else return data['data']; 
                }
                else return null;
              }else return null ;
            }catch(e){
              return null;
            }
        
         // parses JSON response into native JavaScript objects
        }
    async getAllDataBySchoolId(id) {
      try{
      // Default options are marked with *
      const response = await fetch(this.getUrlAllUserBySchoolId+'/'+id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
            if(data['data']==null)
            return null;
           else return data['data']; 
            }
            else return null;
          }else return null ;
        }catch(e){
          return null;
        }
    
     // parses JSON response into native JavaScript objects
    }
     async addData(data,imageFile) {
       try{
        const formData = new FormData();
        if(imageFile!=null)
      { formData.append("image", imageFile);}
      if(data.phone!=null)
      formData.append("phone", data.phone)
      if(data.address!=null)
      formData.append("address", data.address)
      if(data.first_name!=null)
      formData.append("first_name", data.first_name)
      if(data.last_name!=null)
      formData.append("last_name", data.last_name)
      if(data.school_id!=null)
      formData.append("school_id", data.school_id)
      if(data.email!=null)
      formData.append("email", data.email)
      if(data.password!=null)
      formData.append("password", data.password)
      if(data.role_id!=null)
      formData.append("role_id", data.role_id)


       // Default options are marked with *
       const response = await fetch(this.addUrl, {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
        //   'Content-Type': 'application/json',
           'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
           // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         body: formData // body data type must match "Content-Type" header
       });
           if(response.status==200){
             const data = await response.json();
             if(data['response']==201||data['response']==200)
             return data; 
             else return "error";
           }else return"error";
         }catch(e){
        
           return"error";
         }
     
      // parses JSON response into native JavaScript objects
     }
     async updateCreditData(user_id,phone,email) {
   
    
      try{
        const formData = new FormData();
      if(user_id!=null)
      formData.append("id", user_id);
      if(phone!=null)
       formData.append("phone", phone);
       if(email!=null)
       formData.append("email", email);
       
      // Default options are marked with *
      const response = await fetch(this.updateCreditUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
         // 'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body:    formData // body data type must match "Content-Type" header
      });

          if(response.status==200){
            const data = await response.json();
          
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }

    async updateWebFcm(user_id,fcm) {
   
    
      try{
        const formData = new FormData();
      if(user_id!=null)
      formData.append("id", user_id);
      if(fcm!=null)
       formData.append("fcm", fcm);
       
      // Default options are marked with *
      const response = await fetch(this.updateWebFcmUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
         // 'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body:    formData // body data type must match "Content-Type" header
      });

          if(response.status==200){
            const data = await response.json();
          
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }

    
     async updateData(user_id,active,first_name,last_name,phone_secondary,address,imageFile,image_id) {
   
    
      try{
        const formData = new FormData();
        if(imageFile!=null)
      { formData.append("image", imageFile);}
      if(image_id!=null)
      { formData.append("image_id", image_id);}
      if(user_id!=null)
      formData.append("user_id", user_id);
      if(first_name!=null)
       formData.append("first_name", first_name);
       if(last_name!=null)
       formData.append("last_name", last_name);
       if(address!=null)
      formData.append("address", address);
       if(phone_secondary!=null)
      formData.append("phone_secondary", phone_secondary);
      if(active!=null)
      formData.append("active", active);
      // Default options are marked with *
      const response = await fetch(this.updateUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
         // 'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body:    formData // body data type must match "Content-Type" header
      });

          if(response.status==200){
            const data = await response.json();
            console.log("888888888888888888888888$");
            console.log(data);
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
    async syncUser(user_id,school_id,role_id) {
    
      try{
      // Default options are marked with *
      const response = await fetch(this.syncUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
          {
            "user_id":user_id,
            "school_id":school_id,
            "role_id":role_id
    
          }
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
    async unSyncUser(id) {
      console.log("---------==============");
      console.log(id);
    
      try{
      // Default options are marked with *
      const response = await fetch(this.unSyncUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
          {
            "id":id,
          
          }
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }

}
   
export default new UserServices();