import { Row, Col, Form, Button, Dropdown, Modal, Card, Table } from 'react-bootstrap'
import "../../common/custoForm.css"
import { useEffect, useState } from 'react';
import LoadingCompinentCommon from '../../common/commonLoading';
import schoolServices from '../../services/schoolServices';
import { Link, useNavigate, useParams } from 'react-router-dom';
import cityService from '../../services/cityService';
import { useLocation } from "react-router-dom";
import UploadFileService from '../../services/uploadFile';
import HeaderSubTitle from '../../header/headerSubTitle';
import busesService from '../../services/busesService';
import Select from 'react-select';
import api_url from "../../my_config"; 
import UserServices from '../../services/userService';
import "./bus.css" 
import SyncServices from '../../services/syncService';
import moment from "moment";
import RoundedImageTable from "../../components/roundedImages/roundedImage";
import tripService from '../../services/tripService';
import validation from '../../helper/validation';
function UpdateBus() {
    const roleId=localStorage.getItem('roleId');
    const schoolId=localStorage.getItem('schoolId');
    const schoolTitle=localStorage.getItem('schoolTitle');
    const [schoolOPData,setschoolOPData] = useState([]);
    const [schoolUsersOPData,setschoolUsersOPData] = useState([]);
    const [selectedUsersOPData,setselectedUsersOPData] = useState(null);
    const [assignEmp, setAssignEmp] = useState([]);
    const [busTripHistory, setBusTripHistory] = useState([]);
    const { ID } = useParams();
    const navigate = useNavigate();
    const [errorsTtile, setErrorsTtile] = useState(null);
    const [errorsVechicleN, setErrorsVechicleN] = useState(null);
    const [errorsCompanyName, setErrorsCompanyName] = useState(null);
    const [errorColor, setErrorColor] = useState(null);

    const [data, setData] = useState(
        {
            vehicle_number: "",
            company_name: "",
            title: "",
            color: "",
            school_id:"",
            image_id:null,
            image:null
        
    }
    );
    const [isLoading, setLoading] = useState(false);
    const [resSuccess, setResSuccess] = useState(false);
    const [resMsg, setResMsg] = useState('')
    const [show, setShow] = useState(false);
    const [school, setSchool] = useState([]);
    const [dataisLoaded, setDataisLoaded] = useState(false);
    const [isDataNull, setisDataNull] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState(null)
    const [imageId, setImageId] = useState(null)
    const [isImageUploading, setFlagUloadImage] = useState(false)
    function handleClose() {
        setShow(false);
        // if (resSuccess == true)
        //     navigate(-1)
    }
    function getStatusTitle(status){
        switch(status){
          case 1: return "Preparing";
          case 2: return "Started";
          case 3: return "Finished";
          default: return "--";
        }
      
      }
      function getStatusTrip(status){
        switch(status){
          case 1: return "Pick Up";
          case 2: return "Drop";
          default: return "--";
        }
      
      }
    const handleShow = () => setShow(true);
    function getSChoolUsers(school_id) {
        setselectedUsersOPData(null);
        UserServices.getSyncBusEmpSChoolId(schoolId)
        .then((data) => {
            const dataArr=[];
            data.map((e) => {
             
                dataArr.push({value: e.user_id, label: e.first_name+" "+e.last_name});

            });
            setschoolUsersOPData([...dataArr])
            })

    }
    function getAssugnEmp() {
        setselectedUsersOPData(null);
        SyncServices.getBusEmp(ID)
        .then((data) => {
                    setAssignEmp(data);
            })

    }
    function getBusTripHistory() {
        setselectedUsersOPData(null);
        tripService.getHistoryByBusId(ID)
        .then((data) => {
            setBusTripHistory(data);
            })
    }
    
    function getSChool() {
        setLoading(true);
        if(schoolId!="null"&&schoolId!=null)
        {        
   const newData = { ...data }
   newData["school_id"] = schoolId
   setData(newData);
}
                schoolServices.getData()
                .then((data) => {
                    const dataArr=[];
                    data.map((e) => {
                        dataArr.push({
                            value: e.id, label: e.title 
                            
                        });
                    });
                    setschoolOPData([...dataArr]);

                })
            busesService.getDataById(ID)
            .then((data) => {
                if (data == null)
                    setisDataNull(true)
                else {
                    setData({
                        'vehicle_number': data['vehicle_number'],
                        'title':  data['title'],
                        'company_name': data['company_name'],
                        'color': data['color'],
                        'school_id': data['school_id'],
                        'image_id':data['image_id'],
                        'image':data['image'],
                    })
                }

                setLoading(false);

            })
    }
    function handleChangeSchool(e) {
       
        const newData = { ...data }
        newData["school_id"] = e.value
        setData(newData);
        console.log(data);
    }
    useEffect(() => {
        getSChool();
        getSChoolUsers(schoolId);
        getAssugnEmp()
        getBusTripHistory();
    }, []);
    function handleSubmit(e) {
      e.preventDefault();
      let isValidTite=false;
      let isValidVN=false;
      let isValidCopany=false;
      let isValidColor=false;
      const titleValidate=validation.checkNameValidation(data.title);
      if(titleValidate){
          isValidTite=true;
          setErrorsTtile(null);
      }
          else{
              isValidTite=false
              setErrorsTtile("Length must be greater than 3 letters");

          }
          const vNValidate=validation.checkVechicleNValidation(data.vehicle_number);
          if(vNValidate){
              isValidVN=true;
              setErrorsVechicleN(null);
          }
              else{
                  isValidVN=false
                  setErrorsVechicleN("Length must be greater than 5 letters");
              }
              const cNameValidate=validation.checkNameValidation(data.company_name);
              if(cNameValidate){
                  isValidCopany=true;
                  setErrorsCompanyName(null);
              }
                  else{
                      isValidCopany=false
                      setErrorsCompanyName("Length must be greater than 3 letters");
                  }
              const colorValidate=validation.checkColorValidation(data.color);
              if(colorValidate){
                  isValidColor=true;
                  setErrorColor(null);
              }
                  else{
                      isValidColor=false
                      setErrorColor("Length must be greater than 3 letters");
                  }
      if(isValidTite==true&&isValidVN==true&&isValidCopany==true&&isValidColor==true){
        setLoading(true);
        setResMsg('')
        setResSuccess(false);
      
        if(selectedFile!=null)
      { 
        console.log("case 1")
        busesService.updateData(ID,data.school_id,data.title,data.vehicle_number,data.company_name,data.color,selectedFile,data.image_id)
        .then((res) => {
            if (res == 'error') {
                setResMsg('Something went wrong')
            } else {
                if (res['status'] == true) { setResSuccess(true); }
                setResMsg(res['message'])
            }
            handleShow();

            setLoading(false);
        });}
        else if(selectedFile==null){
      
            if(data.image_id==null)
            {  
                console.log("case 2")
                busesService.updateData(ID,data.school_id,data.title,data.vehicle_number,data.company_name,data.color,selectedFile,data.image_id)
                .then((res) => {
                    if (res == 'error') {
                        setResMsg('Something went wrong')
                    } else {
                        if (res['status'] == true) { setResSuccess(true); }
                        setResMsg(res['message'])
                    }
                    handleShow();
        
                    setLoading(false);
                });}
                else if(data.image_id!=null&&data.image!=null)
                {  
                    console.log("case 3")
                    busesService.updateData(ID,data.school_id,data.title,data.vehicle_number,data.company_name,data.color,selectedFile,null)
                    .then((res) => {
                        if (res == 'error') {
                            setResMsg('Something went wrong')
                        } else {
                            if (res['status'] == true) { setResSuccess(true); }
                            setResMsg(res['message'])
                        }
                        handleShow();
            
                        setLoading(false);
                    });
                
                }
                else if(data.image_id!=null&&data.image==null)
                { 
                    console.log("case 4")
                  
                    busesService.updateData(ID,data.school_id,data.title,data.vehicle_number,data.company_name,data.color,selectedFile,data.image_id)
                    .then((res) => {
                        if (res == 'error') {
                            setResMsg('Something went wrong')
                        } else {
                            if (res['status'] == true) { setResSuccess(true); }
                            setResMsg(res['message'])
                        }
                        handleShow();
            
                        setLoading(false);
                    });
                
                }
                
        }
      }
        
    }
    function handleDeleteTripBusEmp(busAssignEmpId) {
        setLoading(true);
        setResMsg("");
        setResSuccess(false);
        SyncServices.deleteBusEmp(busAssignEmpId).then((res) => {
          if (res == "error") {
            setResMsg("Something went wrong");
          } else {
            if (res["status"] == true) {
              setResSuccess(true);
            }
            setResMsg(res["message"]);
          }
          getAssugnEmp();
          handleShow();
          setLoading(false);
        });
      }
    function handleChange(e) {
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        setData(newData);

    }
    function handleRemoveImage(){
        setImage(null);
        setImageId(null);
        setSelectedFile(null);
        const newData = { ...data }
        newData['image'] =null;
        setData(newData);
        console.log(newData);
        
    }
    function handleChangeImage(event) {
        if (event.target.files && event.target.files[0]) {
            if (!event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i))
                 alert('not an image');
    
    else{
        let file_size = event.target.files[0].size;
        if(file_size>0&&file_size<512000)
   {    
    
    const newData = { ...data }
    newData['image'] =null;
    setData(newData);
    setSelectedFile(event.target.files[0])
        setImage(URL.createObjectURL(event.target.files[0]));
    }
        else{
            alert("File Size must be less then 500kb");
            // alert(file_size);
        }
    }
           
        }
    }
    const handleUploadImage = async (e) => {
        setFlagUloadImage(true);
        setResMsg('')
        setResSuccess(false);
        e.preventDefault();
        UploadFileService.addData(selectedFile, 'profile_image').then((res) => {
            if (res == 'error') {
                setResMsg('Something went wrong')
            } else {
                if (res['status'] == true) { 
                     setResSuccess(true); 
                    if(res['id']!=null)
                    setImageId(res['id']);
                }
                setResMsg(res['message'])
            }
            handleShow();
            setFlagUloadImage(false);
        });

    
      };
      function handleChangePOP(e) {}
      function   handleSubmitSyncP() {
   
        setLoading(true);
        setResMsg('')
        setResSuccess(false);
    
    
        SyncServices.syncBusEmp(ID,selectedUsersOPData.value).then((res) => {
            if (res == 'error') {
                setResMsg('Something went wrong')
            } else {
                if (res['status'] == true) { setResSuccess(true); 
                    getAssugnEmp();
                }
                setResMsg(res['message'])
            }
            handleShow();

            setLoading(false);
        });
    }

    return (
        <div className='UpdateBus'>  <HeaderSubTitle title='Update Bus'/>

        <div className="Cutso-Form">
        

            <Modal show={show} onHide={handleClose} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className={resSuccess == false ? 'alert-box-title-error' : 'alert-box-title'}>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>{resMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
                </Modal.Footer>
            </Modal>
            <div className='form-section-update'>
    
              

                    <Form onSubmit={(e) => handleSubmit(e)}>
                    <Select
                   isDisabled={roleId==1?false:true}
                    defaultValue={schoolId=="null"||schoolTitle=="null"||schoolId==null||schoolTitle==null?null:{"value":schoolId,"label":schoolTitle}}
                    onChange={(e) => handleChangeSchool(e)}
                    options={schoolOPData}
                    />
                         
                    {/* <Form.Group  className="mb-3">
                            <Form.Label className='form-label' >
                                School*
                            </Form.Label>
                         
                                <Form.Control required as="select" onChange={(e) => handleChange(e)} value={data.school_id} id='school_id' name='school_id'>
                                    <option key={'empty'} value={''}>...</option>
                                    {
                                        school &&
                                        school.map((p) => {

                                            return (
                                                <option key={p.id} value={p.id}>{p.title}{p.city_name}</option>
                                            );
                                        })}

                                </Form.Control>
                        </Form.Group> */}
                    <Form.Group  className="mb-3">
                    <Form.Label >
                        Title*
                    </Form.Label>
                        <Form.Control
                            isInvalid={!!errorsTtile}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="School Name" name='title' id='title' value={data.title} />
                         <Form.Control.Feedback type="invalid">{errorsTtile}</Form.Control.Feedback>

                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Vehicle Number*
                    </Form.Label>
              
                        <Form.Control 
                           isInvalid={!!errorsVechicleN}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="CG045368" name='vehicle_number' id='vehicle_number' value={data.vehicle_number} />
                                                              <Form.Control.Feedback type="invalid">{errorsVechicleN}</Form.Control.Feedback>

                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Company*
                    </Form.Label>
              
                        <Form.Control
                          isInvalid={!!errorsCompanyName}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="Tata" name='company_name' id='company_name' value={data.company_name} />
                       <Form.Control.Feedback type="invalid">{errorsCompanyName}</Form.Control.Feedback>

                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Color*
                    </Form.Label>
              
                        <Form.Control 
                          isInvalid={!!errorColor}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="Yello" name='color' id='color' value={data.color} />
                                  <Form.Control.Feedback type="invalid">{errorColor}</Form.Control.Feedback>

                </Form.Group>
                {isLoading == true ? <LoadingCompinentCommon /> :
                            isDataNull == true ? <Button  size="sm"   disabled variant="primary" type="submit">
                                Update
                            </Button> : <Button  size="sm" variant="primary" type="submit">
                                Update
                            </Button>}
                    </Form>

          

            </div>
            <div className='image-box'>
           {
           
           data.image!=null&&data.image!=""?
           <img 
           src={'https://techashna.com/busappapifiles/public/uploads/images/'+data.image}
           alt="new"
           />
            :image==null?
            <div className='image-box-add'> <i className="fa fa-image" aria-hidden="true"></i></div>:
                 <img id="image" src={image}/>} 
             
             {
                isLoading==true?
                <></>:
             isImageUploading==true?
             <>
             <br/>
             <LoadingCompinentCommon />
             </>
             :
            
            image==null&&data.image==null?
             <div className="form-group">
             <label htmlFor="myInput" className='image-operation-btn'>Select Image</label>
              <input id="myInput"type="file" name="file" className="mb-4" 

              style={{display:'none'}}
              onChange={(e) => handleChangeImage(e)}  />
             </div>:
             <>
               <button className='image-operation-btn' onClick={handleRemoveImage}>
                 Remove
              </button>
              {/* <button className='image-operation-btn' onClick={handleUploadImage}>
                 Upload Image
              </button> */}
              </>
              }
           </div>
        </div>
        {roleId!=1?
            <Card>
                <Card.Header className='sync-card'>Bus Employee</Card.Header>
                <Card.Body>
                
                <Form>
                <Form.Group className="mb-3">
                    <Form.Label className='form-label' >
                        School*
                    </Form.Label>
                   
                    <Select
                 isDisabled={roleId==1?false:true}
      
                 defaultValue={schoolId=="null"||schoolTitle=="null"||schoolId==null||schoolTitle==null?null:{"value":schoolId,"label":schoolTitle}}

                    onChange={(e) => handleChangePOP(e)}
                    options={schoolOPData}
                    />
                    <br/>
                       <Form.Label className='form-label' >
                       Employees*
                    </Form.Label>
                         
                          <Select
                    value={selectedUsersOPData}
                    defaultValue={selectedUsersOPData}
                    onChange={setselectedUsersOPData}
                    options={schoolUsersOPData}
                    />
                
                 
                 
                </Form.Group>
                {isLoading == true ? <LoadingCompinentCommon /> :
                            <Button  size="sm" onClick={()=>handleSubmitSyncP()} disabled={selectedUsersOPData==null?true:false} variant="primary">
                                Update
                            </Button> }
              
    
            </Form>

                </Card.Body>
            </Card>:
            <></>}
                <Table striped className="body-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Assign Date</th>
                    <th>Option</th>
                  </tr>
                </thead>
                <tbody>
                  {assignEmp.length == 0
                    ? "No Data Found"
                    : assignEmp &&
                      assignEmp.map((p,index) => {
                        let dateAndTimeC = "";
                        let dateAndTimeU = "";

                        dateAndTimeC = moment(p.created_at)
                          .utc()
                          .format("YYYY-MM-DD hh:mm:ss");
                        dateAndTimeU = moment(p.updated_at)
                          .utc()
                          .format("YYYY-MM-DD hh:mm:ss");
                        return (
                          <tr key={p.id}>
                            <td>{index+1}</td>
                            <td>
                    {p.image!=""&& p.image!=null?
        <RoundedImageTable imageUrl={'https://techashna.com/busappapifiles/public/uploads/images/'+p.image}/>:
        
     <> <i className="fa fa-image fa-sm" aria-hidden="true"></i></>      }                          </td>
                            <td>
                              {p.first_name} {p.last_name}
                            </td>
                            <td>{dateAndTimeU}</td>
                            <td>
                              {" "}
                              <button
                                onClick={() => handleDeleteTripBusEmp(p.id)}
                                className="btn btn-delete"
                              >
                                {" "}
                                <i
                                  className="fa fa-trash fa-sm"
                                  aria-hidden="true"
                                ></i>
                              </button>{" "}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>
              <Card>
                <Card.Header className='sync-card'>Bus Trip History</Card.Header>
        
                    </Card>
                    <Table striped className="body-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Trip Name</th>
                    <th>Trip</th>
                    <th>Status</th>
                    <th>Start Date</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>End Date</th>
                    <th>Assign Date</th>
                    <th>Option</th>
                  </tr>
                </thead>
                <tbody>
                  {busTripHistory.length == 0
                    ? "No Data Found"
                    : busTripHistory &&
                    busTripHistory.map((p,index) => {
                        let dateAndTimeC = "";
                        let dateAndTimeU = "";

                        dateAndTimeC = moment(p.created_at)
                          .utc()
                          .format("YYYY-MM-DD hh:mm:ss");
                        dateAndTimeU = moment(p.updated_at)
                          .utc()
                          .format("YYYY-MM-DD hh:mm:ss");
                        return (
                          <tr key={p.id}>
                            <td>{index+1}</td>
                            <td>
                              {p.trip_title}
                            </td>
                            <td>
                            {getStatusTrip(p.is_pick_up)}
                            
                            </td>
                            <td>
                              {getStatusTitle(p.status)}
                            </td>
                            <td>
                              {p.start_date}
                            </td>
                            <td>
                              {p.start_time}
                            </td>
                            <td>
                              {p.end_date}
                            </td>
                            <td>
                              {p.end_time}
                            </td>

                            <td>{dateAndTimeC}</td>
                            <td>
                              {" "}
                              <Link to={'/UpdateTripHistory/'+p.id}>
                              <button
                            
                                className="btn btn-edit"
                              >
                                {" "}
                                <i
                                  className="fa fa-edit fa-sm"
                                  aria-hidden="true"
                                ></i>
                              </button>{" "}
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>
              <br/>
        </div>
    );

}

export default UpdateBus;