import "../sideNav/sideNavBar.css";
import  {Link, useLocation} from "react-router-dom";
import AdminProfile from "../components/adminProfile/adminProfile";
function SideNavBar() {

  const roleId=localStorage.getItem('roleId');
  

  const schoolId=localStorage.getItem('schoolId');
  const location = useLocation()
  const pathName=location.pathname 
  console.log(pathName);
  return (
    <div className="sideNavBar">
      <ul>
        <br></br>
        <AdminProfile/>
        <br></br>
        <Link to="/SchoolDashBoard" style={{ textDecoration: 'none' }}>
        <li className={pathName=="/SchoolDashBoard"?"currentPath":""}>
          <span className="icon">
            <i className="fa fa-home" aria-hidden="true"></i>
          </span>
          <span className="title">Dashboard</span>
        </li>
  
        </Link>
      
        {
          roleId==1? 
        
          <Link to="/SchoolTable" style={{ textDecoration: 'none' }}>
        <li className={pathName=="/SchoolTable"?"currentPath":""}>
          <span className="icon">
            <i className="fa fa-home" aria-hidden="true"></i>
          </span>
          <span className="title">School</span>
        </li>
  
        </Link>
        :
        roleId==2||roleId==3? 
        <Link to={'/UpdateSchool/'+schoolId}
         style={{ textDecoration: 'none' }}>
      <li className={pathName=="/UpdateSchool"?"currentPath":""}>
        <span className="icon">
          <i className="fa fa-home" aria-hidden="true"></i>
        </span>
        <span className="title">School</span>
      </li>
      </Link>:
        <></>
      }
       { roleId==1||roleId==2||roleId==3? 
        <Link to="/UserTable" style={{ textDecoration: 'none' }}>
     
        <li className={pathName=="/UserTable"?"currentPath":""}>
          <span className="icon">
            <i className="fa fa-home" aria-hidden="true"></i>
          </span>
          <span className="title">Users</span>
        </li>
      
        </Link>:<></>
       }
        
        {/* <a>
        <li>
          <span className="icon">
            <i className="fa fa-home" aria-hidden="true"></i>
          </span>
          <span className="title">Students</span>
        </li>
        </a> */}
         {/* {
            roleId==2?
        <Link to="/EmpTable" style={{ textDecoration: 'none' }}>
        <li className={pathName=="/EmpTable"?"currentPath":""}> 
          <span className="icon">
            <i className="fa fa-home" aria-hidden="true"></i>
          </span>
          <span className="title">Employees</span>
        </li>
        </Link>
           :
           <></>
         } */}
           {
         roleId==2||roleId==3||roleId==9?
        <Link to="/BusTable" style={{ textDecoration: 'none' }}>
     
     <li className={pathName=="/BusTable"?"currentPath":""}>
       <span className="icon">
         <i className="fa fa-home" aria-hidden="true"></i>
       </span>
       <span className="title">Buses</span>
     </li>
   
     </Link>
     :<></>}
        {
          roleId==2||roleId==3||roleId==9?
             <Link to="/TripTable" style={{ textDecoration: 'none' }}>
     
     <li className={pathName=="/TripTable"?"currentPath":""}>
       <span className="icon">
         <i className="fa fa-home" aria-hidden="true"></i>
       </span>
       <span className="title">Trip</span>
     </li>
  
     </Link>
          :<></>}
                 {
          roleId==2||roleId==3||roleId==9?
             <Link to="/TripHistory" style={{ textDecoration: 'none' }}>
     
     <li className={pathName=="/TripHistory"?"currentPath":""}>
       <span className="icon">
         <i className="fa fa-home" aria-hidden="true"></i>
       </span>
       <span className="title">Trip History</span>
     </li>
   
     </Link>
          :<></>}
                 {
          roleId==2?
             <Link to="/TrackAllBuses" style={{ textDecoration: 'none' }}>
     
     <li className={pathName=="/TrackAllBuses"?"currentPath":""}>
       <span className="icon">
         <i className="fa fa-home" aria-hidden="true"></i>
       </span>
       <span className="title">Live Bus Track</span>
     </li>
   
     </Link>
          :<></>}
          
                   {
          roleId==2||roleId==3||roleId==9?
             <Link to="/NotificationTable" style={{ textDecoration: 'none' }}>
     
     <li className={pathName=="/NotificationTable"?"currentPath":""}>
       <span className="icon">
         <i className="fa fa-home" aria-hidden="true"></i>
       </span>
       <span className="title">Notificaiton</span>
     </li>
   
     </Link>
          :<></>}
   
      </ul>
    </div>
  );
}

export default SideNavBar;
